import * as React from 'react';

// components
import ClientData from './ClientData';
import SamplesData from './SamplesData';
import ProjectInformation from './ProjectInformation';
import SamplesTblHeader from './SamplesTblHeader';
import TestingRow from './TestingRow';
import BottleRow from './BottleRow.js';
import PackageList from './PackageList';
import AdminComments from './AdminComments.js';
import SignaturesBody from './SignaturesBody.js';
import BodyAlerts from './BodyAlerts.js';

// mui
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const COCBody = ({ userInput, renderSamples, data, samplingEventMode, samplingEventInputs, setSamplingEventInputs, setUserData, SEOR, setMode, page, setGlobalLoading, samplingMode, showBottles }) => {

    const uniqueTestList = () => {
        
        const uniques = []
        let allInds = []
        userInput.samples.forEach(s => {
            s.activeTests?.filter(t => !t.individual).forEach(t => {
                const findTest = uniques.find(test => test.LIMS_ID === t.LIMS_ID)
                if (!findTest) {
                    uniques.push(t)
                }
            })
            const individuals = []

            s.activeTests?.filter(t => t.individual).forEach(t => {
                const findGroup = individuals.find(g => g.individualName === t.individualName)
                if (findGroup) {
                  findGroup.activeTests.push(t)
                } else {
                  individuals.push({
                    individualName: t.individualName,
                    activeTests: [t]
                  })
                }
            })

            const checks = [...allInds, ...individuals].map(g => JSON.stringify(g.activeTests.map(at => at.LIMS_ID).sort()))            
            allInds = [...allInds, ...individuals].filter((item,index) => checks.indexOf(JSON.stringify(item.activeTests.map(at => at.LIMS_ID).sort())) === index)
            
        })

        
        allInds.forEach(t => {
            if (!t.index && allInds.filter(g => g.individualName === t.individualName).length > 1) {
                allInds.filter(g => g.individualName === t.individualName).forEach((gi, i) => {
                    gi.index = i + 1
                })
            }
        })

        return [...uniques, ...allInds]        
    }

    const testingList = uniqueTestList();

    const bottleList = userInput.samples.map(s => s.activeBottles).flat()
    const bottleHeaderList = bottleList?.filter((obj, index) => bottleList.findIndex((item) => item?.BOTTLE_LIMS_ID === obj?.BOTTLE_LIMS_ID) === index).filter(Boolean).sort((a, b) => a.BOTTLE_NAME?.localeCompare(b.BOTTLE_NAME))

    const getHeaders = () => {
        
        const headers = []
        const returnHeaders = []
        // this will need to be flexible somehow
        const sampleData = data.forms.sections.find(f => f.type === 'sample')
        const inputRefs = sampleData.sub_sections.map(ss => ss.inputs).flat()
        userInput.samples.forEach(s => {
            
            for (const [key, value] of Object.entries(s)) {
                if (headers.indexOf(key) < 0 && !Array.isArray(value) && value !== null) {
                    
                    const refIndex = inputRefs.findIndex(item => item.input_key === key)
                    if (refIndex > -1) {
                        returnHeaders.splice(refIndex, 0, key);
                    }
                    headers.push(key)
                }

            }

        })

        if (userInput.samplingEventInputs?.length) {
            userInput.samplingEventInputs.forEach(s => {
                for (const [key, value] of Object.entries(s)) {
                    if (headers.indexOf(key) < 0 && !Array.isArray(value) && data.samplingEvent?.samplerInputs[key] && data.samplingEvent.samplerInputs[key].COCRender && !SEOR && value !== null) {
                        
                        const refIndex = data.samplingEvent ? data.samplingEvent.samplerInputs[key] : false
                        
                        if (refIndex?.order > -1) {
                            returnHeaders.splice(refIndex.order, 0, key);
                        }
    
                        headers.push(key)
                    }

                    if (samplingMode) {
                        const samplingForm = data?.forms?.sections?.find(s => s.type === 'samplingEvent' && s.samplingEventKey === userInput.submissionType.split(',')[1])
                        const fullInputs = samplingForm?.sub_sections.map(ss => ss.inputs).flat()
                        const foundHeader = fullInputs?.find(input => input.input_key === key)?.input_key

                        if (foundHeader && !returnHeaders.includes(foundHeader)) {
                            returnHeaders.push(foundHeader)
                        }                        
                    }
    
                }
    
            })

        }

        returnHeaders.splice(0, 0, "id");
        
        // at some point this service line will become a "mode" and this can be cleaned up but for now this is ONLY for cannabis-sampling
        if (userInput.serviceLine === 'cannabis-sampling' && userInput.samplingEventInputs?.length && !SEOR && !samplingMode) {returnHeaders.push("Total Mass Taken")}
        
        // sampling duplicate, inject at index 1 (2nd entry)
        if (userInput.samples.length > 1 && !!userInput.samples[0]?.samplingDuplicate && !SEOR) {          
            returnHeaders.splice(2, 0, "samplingDuplicate");
        } 

        return returnHeaders
    }

    const headers = getHeaders()
    const standardHeadersLength = headers.length    
    const samplingEventHeadersLength = Object.keys(data.samplingEvent?.samplerInputs ?? {}).length

    const headerCount = samplingEventMode ? standardHeadersLength + samplingEventHeadersLength : standardHeadersLength




  return (
    <TableContainer>
    <Table>
        <TableHead>
            
            {/* Row 1 */}
            <TableRow>                

                {/* Client Data */}
                <TableCell className="clientArea" colSpan={Math.ceil(headerCount / 2)} rowSpan={2}  >                                                
                    <ClientData userInput={userInput} data={data} />
                </TableCell>
                <TableCell className="cocCell" colSpan={Math.floor(headerCount / 2)} rowSpan={2}  >                                                
                    <ProjectInformation userInput={userInput} data={data} />
                </TableCell>

                <td className="cocCell" align="center" colSpan={testingList?.length}>Testing</td>
                {bottleHeaderList?.length && data.forms?.bottlesMenu  && (!samplingMode || showBottles) ? <td className="cocCellBottle" align="center" colSpan={bottleHeaderList?.length}>Bottles</td> : null}

            </TableRow>
            
            {/* methods header, row 2 */}
            <TableRow>
                <TestingRow testingList={testingList} data={data} />
                {bottleHeaderList?.length && data.forms?.bottlesMenu && (!samplingMode || showBottles) ? <BottleRow bottleList={bottleHeaderList} data={data} /> : null}
                
            </TableRow>
            {/* Samples Headers */}
            <SamplesTblHeader headers={headers} data={data} userInput={userInput} samplingEventMode={samplingEventMode} />

        </TableHead>
        
        {/* Samples Table */}
        <TableBody style={{height: "66%"}}>            
            <SamplesData bottleList={bottleHeaderList} page={page} testingList={testingList} renderSamples={renderSamples} headers={headers} samplingEventMode={samplingEventMode} data={data} samplingEventInputs={samplingEventInputs} setSamplingEventInputs={setSamplingEventInputs} userInput={userInput} setUserData={setUserData} samplingMode={samplingMode} showBottles={showBottles} />
        </TableBody>
        
                
        </Table>
        
        {/* List out packages and their contents here */}
        <PackageList data={data} testingList={testingList} />

        {/* Signatures between client and admin */}
        <SignaturesBody data={data} userInput={userInput} samplingMode={samplingMode} />

        {/* Comments if applicable, only show on page 1 */}
        {page === 0 && userInput?.comments?.length > 0 ? <AdminComments userInput={userInput} /> : null}

        {/* Alerts can go here */}
        <BodyAlerts userInput={userInput} samplingMode={samplingMode} />


        </TableContainer>    
  );
}

export default COCBody;


